import Image from "next/image";

import { cn } from "@gsa/ui/lib/utils";

import { loader } from "~/lib/loader";

export function Logo({ className }: { className?: string }) {
  const width = 1000;
  const height = 610;

  const targetWidth = 256;
  const ratio = width / height;
  const targetHeight = targetWidth / ratio;

  return (
    <Image
      priority
      alt="GoldStandardX Logo"
      loader={loader}
      src="/gsa-production/images/gsx/image"
      width={targetWidth}
      height={targetHeight}
      draggable={false}
      className={cn("h-8 w-auto", className)}
    />
  );
}

export function LogoOld({ className }: { className?: string }) {
  const width = 1000;
  const height = 610;

  const targetWidth = 256;
  const ratio = width / height;
  const targetHeight = targetWidth / ratio;

  return (
    <Image
      priority
      alt="GoldStandardX Logo"
      loader={loader}
      src="/gsa-production/images/gsx-old/image"
      width={targetWidth}
      height={targetHeight}
      className={cn("h-8 w-auto", className)}
    />
  );
}
