import clsx from "clsx";

import { cn } from "../lib/utils";

const Price = ({
  amount,
  className,
  currencyCode = "USD",
  currencyCodeClassName,
  maximumFractionDigits = 2,
  divideBy = 100,
}: {
  amount: string | number;
  className?: string;
  currencyCode?: string;
  currencyCodeClassName?: string;
  maximumFractionDigits?: number;
  divideBy?: number;
} & React.ComponentProps<"p">) => (
  <p
    suppressHydrationWarning={true}
    className={cn("inline-flex items-center gap-1", className)}
  >
    {`${new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol",
      maximumFractionDigits: amount === 0 ? 0 : maximumFractionDigits,
    }).format(
      (typeof amount === "string" ? Number(amount) : amount) / divideBy,
    )}`}
  </p>
);

export default Price;
