"use client";

import Image from "next/image";
import Link from "next/link";

import type { CategoryListItem } from "@gsa/core/resources/store/category";
import type { CollectionListResult } from "@gsa/core/resources/store/collection";
import { cn } from "@gsa/ui/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@gsa/ui/navigation-menu";

import { robotoSlab } from "~/lib/fonts";
import { Logo } from "../../logo";
import ListItem from "./list-item";

export function NavMenu({
  categories,
  collections,
}: {
  categories: CategoryListItem[];
  collections: CollectionListResult["items"];
}) {
  return (
    <>
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger
              className={cn(robotoSlab.className, "bg-transparent text-lg")}
            >
              Discover
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[700px] lg:grid-cols-[.75fr_1fr]">
                <li className="row-span-4">
                  <NavigationMenuLink asChild>
                    <Link
                      className="relative flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                      href="/about"
                    >
                      <Image
                        src="/images/fine-gold.jpg"
                        alt="Fine gold background"
                        className="absolute inset-0 object-cover object-center opacity-10"
                        fill
                      />

                      <div>
                        <Logo className="mb-4 h-14" />
                      </div>

                      <p className="text-sm leading-tight text-muted-foreground">
                        The ultimate destination for collectors and enthusiasts
                        of rare and precious items.
                      </p>
                    </Link>
                  </NavigationMenuLink>
                </li>
                <ListItem href="/search/collections/deals" title="Deals">
                  View our latest deals and promotions.
                </ListItem>
                <ListItem
                  href="/search/collections/top-sellers"
                  title="Top Sellers"
                >
                  Discover the best-selling items in our collection.
                </ListItem>
                <ListItem
                  href="/search/collections/popular-products"
                  title="Popular Products"
                >
                  Explore the most popular items in our collection.
                </ListItem>

                <ListItem
                  href="/search/collections/new-arrivals"
                  title="New Arrivals"
                >
                  Stay up-to-date with the latest additions to our collection.
                </ListItem>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>

          <Link href="/search" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              All Products
            </NavigationMenuLink>
          </Link>

          <Link href="/search/gold" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              Gold
            </NavigationMenuLink>
          </Link>

          <Link href="/search/silver" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              Silver
            </NavigationMenuLink>
          </Link>

          <Link href="/search/palladium" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              Palladium
            </NavigationMenuLink>
          </Link>

          <Link href="/search/platinum" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              Platinum
            </NavigationMenuLink>
          </Link>

          <Link href="/search/copper" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              Copper
            </NavigationMenuLink>
          </Link>

          <Link href="/collections" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              Collections
            </NavigationMenuLink>
          </Link>

          {/* {categories?.items.map((category) => {
            const children = category?.children.filter(
              (c) => c.productCount > 0,
            );
            return (
              <NavigationMenuItem key={category?.id}>
                <NavigationMenuTrigger className={robotoSlab.className}>
                  {category?.title}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul
                    className={cn(
                      "grid gap-3 p-6 md:w-[400px] ",
                      (category?.children.length ?? 0) > 10
                        ? " lg:w-[500px] xl:w-[800px] xl:grid-cols-2"
                        : "",
                    )}
                  >
                    <ListItem
                      title={`All ${category?.title}`}
                      href={`/search/${category?.slug}`}
                    />
                    {children.length > 0
                      ? children.map((child) => (
                          <ListItem
                            key={child.id}
                            title={child.title}
                            href={`/search/${child.slug}`}
                            className={cn(
                              "text-md font-normal text-muted-foreground",
                            )}
                          />
                        ))
                      : null}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            );
          })} */}

          {/* <NavigationMenuItem>
            <NavigationMenuTrigger className={robotoSlab.className}>
              Collections
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className={cn("grid gap-3 p-6 md:w-[400px] ")}>
                {collections?.items.map((collection) => (
                  <ListItem
                    title={collection.title}
                    href={`/search/collections/${collection.slug}`}
                  />
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem> */}

          {/* <NavigationMenuItem>
          <NavigationMenuTrigger className={robotoSlab.className}>
            Components
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {categories?.items.map((component) => (
                <ListItem
                  key={component.id}
                  title={component.title}
                  href={`/search/${component.slug}`}
                >
                  {component.children.map((child) => (
                    <ListItem
                      key={child.id}
                      title={child.title}
                      href={`/search/${child.slug}`}
                    />
                  ))}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem> */}

          {/* <NavigationMenuItem>
          <Link href="/docs" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              Documentation
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem> */}
        </NavigationMenuList>
      </NavigationMenu>

      <NavigationMenu>
        <NavigationMenuList>
          <Link href="/sell" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg",
                robotoSlab.className,
              )}
            >
              Sell To Us
            </NavigationMenuLink>
          </Link>

          <Link
            href="https://goldstandardauctions.hibid.com/"
            legacyBehavior
            passHref
            target="_blank"
            rel="noopener noreferrer"
          >
            <NavigationMenuLink
              className={cn(
                navigationMenuTriggerStyle(),
                "bg-transparent text-lg text-green-600",
                robotoSlab.className,
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Our Auctions
            </NavigationMenuLink>
          </Link>
        </NavigationMenuList>
      </NavigationMenu>
    </>
  );
}
