"use client";

import { useRef } from "react";
import { useQueryState } from "nuqs";
import { useDebouncedCallback } from "use-debounce";

import { SearchIcon, XIcon } from "../icons";
import { cn } from "../lib/utils";
import { Input } from "./ui/input";

export default function Search({
  pathRedirect,
  placeholder,
  className,
  prefix = "",
  onSearch,
  onClear,
}: {
  pathRedirect?: string;
  placeholder?: string;
  className?: string;
  prefix?: string;
  onSearch?: (term: string) => void;
  onClear?: () => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useQueryState(`${prefix ? `${prefix}_` : ""}q`);
  const [, setPage] = useQueryState(`${prefix ? `${prefix}_` : ""}page`);

  const handleSearch = useDebouncedCallback((term: string) => {
    setPage("1");
    if (term) {
      setQuery(term.trim());
      onSearch?.(term.trim());
    } else {
      setQuery(null);
      onClear?.();
    }
  }, 300);

  const clearSearch = () => {
    setQuery(null);
    setPage("1");

    if (inputRef.current) {
      inputRef.current.value = "";
    }

    onClear?.();
  };

  return (
    <div className={cn("relative flex w-full flex-1 items-center", className)}>
      <div className="flex flex-1 items-center">
        <label className="w-full">
          <span className="sr-only">Search</span>
          <Input
            name="search"
            ref={inputRef}
            placeholder={placeholder}
            onChange={(e) => handleSearch(e.target.value)}
            defaultValue={query ?? ""}
            className="h-10 w-full pl-8"
          />
        </label>
        <SearchIcon className="absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />
        {query && (
          <XIcon
            onClick={clearSearch}
            className="absolute right-6 top-1/2 h-[18px] w-[18px] -translate-y-1/2 cursor-pointer text-gray-950 hover:text-gray-500"
          />
        )}
      </div>
    </div>
  );
}
