// app/PostHogPageView.tsx
"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";

import type { User } from "@gsa/auth/types";

export default function PostHogPageView({
  user,
}: {
  user: User | null | undefined;
}): null {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = `${url}?${searchParams.toString()}`;
      }
      posthog.capture("$pageview", {
        $current_url: url,
      });

      if (user) {
        posthog.identify(
          user.id, // Replace 'distinct_id' with your user's unique identifier
          { email: user.email, name: user.name }, // optional: set additional user properties
        );
      }
    }
  }, [pathname, searchParams, posthog, user]);

  return null;
}
