"use client";

import { useEffect, useState } from "react";
import Link from "next/link";

import { CoinsIcon } from "@gsa/ui/icons";
import { toast } from "@gsa/ui/index";

import { loader } from "~/lib/loader";
import { ProductImage } from "./product/image";

interface RecentOrder {
  shippingState: string | null;
  shippingCity: string | null;
  firstName: string | null;

  items: {
    quantity: number;
    product: {
      title: string;
      slug: string;
      images: {
        path: string;
        width: number | null;
        height: number | null;
      }[];
    };
  }[];
}

export function RecentOrderToast({ orders }: { orders: RecentOrder[] }) {
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if (currentIndex > -1) {
      toast(<RecentOrder order={orders[currentIndex]} />);
    }

    const MINIMUM = 30 * 1000;
    const MAXIMUM = 150 * 1000;

    const randomTime = Math.floor(
      Math.random() * (MAXIMUM - MINIMUM) + MINIMUM,
    );

    setTimeout(() => {
      const randomIndex = Math.floor(Math.random() * orders.length);

      setCurrentIndex(randomIndex);
    }, randomTime);
  }, [currentIndex]);

  return null;
  //   return (
  //     <div>
  //       <pre>{JSON.stringify(orders, null, 2)}</pre>
  //     </div>
  //   );
}

export function RecentOrder({ order }: { order: RecentOrder }) {
  return (
    <div className="flex flex-col gap-4 print:hidden">
      <div className="text-md">
        <span className="font-semibold">{order.firstName ?? "Someone"}</span>{" "}
        from{" "}
        <span className="font-semibold">
          {[order.shippingCity, order.shippingState].filter(Boolean).join(", ")}
        </span>{" "}
        just ordered {order.items?.length} items
      </div>
      <div className="flex flex-col gap-1">
        {order.items?.map((item) => (
          <Link
            href={`/products/${item.product.slug}`}
            key={item.product.slug}
            className="flex w-full items-center gap-2 overflow-hidden rounded-lg border p-2"
          >
            {item.product.images?.[0] && (
              <ProductImage
                src={item.product.images?.[0].path}
                loader={loader}
                alt={item.product.title}
                width={item.product.images?.[0].width ?? 512}
                height={item.product.images?.[0].height ?? 512}
                className="aspect-square h-16 w-auto"
              />
            )}

            {!item.product.images?.[0] && (
              <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-slate-100">
                <CoinsIcon className="h-8 w-8 text-muted-foreground/50" />
              </div>
            )}

            <div className="p-2">
              <span className="font-semibold">{item.product.title}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
