"use client";

import { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@gsa/ui/components/ui/dialog";

import NewsletterForm from "./forms/newsletter-form";
import { Logo } from "./logo";
import { SpacerImage } from "./spacer";

const hasSubscribed = () => {
  return !!localStorage.getItem("newsletter-subscribed");
};

const ignoredRecently = () => {
  const lastIgnored = localStorage.getItem("newsletter-ignored-at");

  return (
    !!lastIgnored &&
    new Date(lastIgnored).getTime() > new Date().getTime() - 24 * 60 * 60 * 1000
  );
};

const setHasIgnored = () => {
  localStorage.setItem("newsletter-ignored-at", new Date().toISOString());
};

export function DontMissOutPopup() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const shouldShow = !hasSubscribed() && !ignoredRecently();
    if (shouldShow) {
      setTimeout(() => {
        setOpen(true);
      }, 15000);
    }
  }, []);

  return (
    <Dialog
      open={open && !ignoredRecently() && !hasSubscribed()}
      onOpenChange={() => {
        setHasIgnored();
        setOpen(false);
      }}
    >
      <DialogContent className="p-8 print:hidden">
        <DialogHeader className="mb-8 gap-8">
          <Logo className="h-12 w-auto object-contain" />
          <SpacerImage />
          <DialogTitle>Dont Miss Out On The Latest Offers</DialogTitle>
          <DialogDescription>
            Subscribe to our newsletter to stay up to date with new products and
            exlusive promotions.
          </DialogDescription>
        </DialogHeader>

        <NewsletterForm
          onSubscribe={() =>
            setTimeout(() => {
              setOpen(false);
            }, 2000)
          }
        />
      </DialogContent>
    </Dialog>
  );
}
