export const CART_COOKIE_NAME = "gsa.cart";
export const ContactInfo = {
  Phone: "972-800-6456",
  Email: "sales@goldstandardauctions.com",
};

export type SortFilterItem = {
  title: string;
  slug: string | null;
  sortKey: "RELEVANCE" | "BEST_SELLING" | "CREATED_AT" | "PRICE";
  reverse: boolean;
};

export const defaultSort: SortFilterItem = {
  title: "Relevance",
  slug: null,
  sortKey: "RELEVANCE",
  reverse: false,
};

export const sorting: SortFilterItem[] = [
  defaultSort,
  {
    title: "Trending",
    slug: "trending-desc",
    sortKey: "BEST_SELLING",
    reverse: false,
  }, // asc
  {
    title: "Latest arrivals",
    slug: "latest-desc",
    sortKey: "CREATED_AT",
    reverse: true,
  },
  {
    title: "Price: Low to high",
    slug: "price-asc",
    sortKey: "PRICE",
    reverse: false,
  }, // asc
  {
    title: "Price: High to low",
    slug: "price-desc",
    sortKey: "PRICE",
    reverse: true,
  },
];

export const TAGS = {
  collections: "collections",
  categories: "categories",
  products: "products",
  cart: "cart",
  orders: "orders",
  customer: "customer",
  myOrders: "my-orders",
};

export const HIDDEN_PRODUCT_TAG = "nextjs-frontend-hidden";
export const DEFAULT_OPTION = "Default Title";

export function getMenu(type: "header-menu"): Menu[] {
  switch (type) {
    case "header-menu":
      return getHeaderMenu();
  }
}

export type Menu = {
  label: string;
  target?: string;
  href?: string;
  items?: Menu[];
  sections?: Menu[];
};

function getHeaderMenu() {
  return [
    {
      label: "Search",
      href: "/search",
      items: [
        {
          label: "In Stock Gold",
          href: "/search/gold",
        },
        {
          label: "In Stock Silver",
          href: "/search/silver",
        },
        {
          label: "In Stock Platinum",
          href: "/search/platinum",
        },
      ],
    },
    {
      label: "Gold",
      href: "/search/gold",
      sections: [
        {
          label: "Gold Coins",
          items: [
            {
              label: "US Gold Eagles",
              href: "/search/us-gold-eagles",
            },
            {
              label: "Gold Maple Leafs",
              href: "/search/gold-maple-leafs",
            },
            {
              label: "US Gold Buffalos",
              href: "/search/us-gold-buffalos",
            },
            {
              label: "Australian Gold Coins",
              href: "/search/australian-gold-coins",
            },
            {
              label: "British Gold Coins",
              href: "/search/british-gold-coins",
            },
            {
              label: "Chinese Gold Pandas",
              href: "/search/chinese-gold-pandas",
            },
            {
              label: "African Gold Coins",
              href: "/search/african-gold-coins",
            },
            {
              label: "Gold Philharmonics",
              href: "/search/gold-philharmonics",
            },
            {
              label: "Mexican Gold Libertads",
              href: "/search/mexican-gold-libertads",
            },
          ],
        },

        {
          label: "Gold Bars",
          href: "/search/gold-bars",
          items: [
            {
              label: "All Gram Bars",
              href: "/search/all-gram-bars",
            },
            {
              label: "1 oz Gold Bars",
              href: "/search/1-oz-gold-bars",
            },
            {
              label: "1 Kilo Gold Bars",
              href: "/search/1-kilo-gold-bars",
            },
            {
              label: "All Large Bars",
              href: "/search/all-large-bars",
            },
            {
              label: "Argor-Heraeus Gold Bars",
              href: "/search/argor-heraeus-gold-bars",
            },
            {
              label: "Valcambi Gold Bars",
              href: "/search/valcambi-gold-bars",
            },
          ],
        },

        {
          label: "Other Gold",
          href: "/search/other-gold",
          items: [
            {
              label: "Pre-33 Gold",
              href: "/search/pre-33-gold",
            },
            {
              label: "Truth Coin Series",
              href: "/search/truth-coin-series",
            },
            {
              label: "SD24K Gold Jewelry",
              href: "/search/sd24k-gold-jewelry",
            },
            {
              label: "Foil Notes",
              href: "/search/foil-notes",
            },
            {
              label: "Fractional Gold",
              href: "/search/fractional-gold",
            },
            {
              label: "2023 Gold Releases",
              href: "/search/2023-gold-releases",
            },
            {
              label: "IRA Approved Gold",
              href: "/search/ira-approved-gold",
            },
          ],
        },
      ],
    },

    {
      label: "Silver",
      href: "/search/silver",
      sections: [
        {
          label: "Silver Coins",
          href: "/search/silver-coins",

          items: [
            {
              label: "US Silver Eagles",
              href: "/search/us-silver-eagles",
            },
            {
              label: "Silver Maple Leafs",
              href: "/search/silver-maple-leafs",
            },
            {
              label: "90% Junk Silver",
              href: "/search/90-junk-silver",
            },
            {
              label: "Silver Dollars",
              href: "/search/silver-dollars",
            },
            {
              label: "Truth Coin Series",
              href: "/search/truth-coin-series",
            },
            {
              label: "African Silver Coins",
              href: "/search/african-silver-coins",
            },
            {
              label: "Australian Perth Mint",
              href: "/search/australian-perth-mint",
            },
            {
              label: "Austrian Philharmonics",
              href: "/search/austrian-philharmonics",
            },
            {
              label: "British Silver Coins",
              href: "/search/british-silver-coins",
            },
            {
              label: "Chinese Silver Pandas",
              href: "/search/chinese-silver-pandas",
            },
            {
              label: "New Zealand Silver",
              href: "/search/new-zealand-silver",
            },
            {
              label: "Mexican Silver Libertads",
              href: "/search/mexican-silver-libertads",
            },
          ],
        },

        {
          label: "Silver Rounds",
          href: "/search/silver-rounds",

          items: [
            {
              label: "Fractional Silver Rounds",
              href: "/search/fractional-silver-rounds",
            },
            {
              label: "1 oz Silver Rounds",
              href: "/search/1-oz-silver-rounds",
            },
            {
              label: "2 oz Silver Rounds",
              href: "/search/2-oz-silver-rounds",
            },
            {
              label: "5 oz Silver Rounds",
              href: "/search/5-oz-silver-rounds",
            },
            {
              label: "Intaglio Mint Silver Rounds",
              href: "/search/intaglio-mint-silver-rounds",
            },
            {
              label: "The Awakening",
              href: "/search/the-awakening",
            },
          ],
        },

        {
          label: "Silver Bars",
          href: "/search/silver-bars",

          items: [
            {
              label: "1oz Silver Bars",
              href: "/search/1oz-silver-bars",
            },
            {
              label: "5oz Silver Bars",
              href: "/search/5oz-silver-bars",
            },
            {
              label: "10oz Silver Bars",
              href: "/search/10oz-silver-bars",
            },
            {
              label: "Kilo Silver Bars",
              href: "/search/kilo-silver-bars",
            },
            {
              label: "100oz Silver Bars",
              href: "/search/100oz-silver-bars",
            },
            {
              label: "1000oz Silver Bars",
              href: "/search/1000oz-silver-bars",
            },
            {
              label: "Gram Silver Bars",
              href: "/search/gram-silver-bars",
            },
          ],
        },

        {
          label: "Other Silver",
          href: "/search/other-silver",

          items: [
            {
              label: "❄ Holiday Collection ❄",
              href: "/search/holiday-collection",
            },
            {
              label: "Scottsdale Silver",
              href: "/search/scottsdale-silver",
            },
            {
              label: "Starter Packs",
              href: "/search/starter-packs",
            },
            {
              label: "2023 Silver Releases",
              href: "/search/2023-silver-releases",
            },
            {
              label: "IRA Approved Silver",
              href: "/search/ira-approved-silver",
            },
            {
              label: "Other World Silver",
              href: "/search/other-world-silver",
            },
          ],
        },
      ],
    },

    {
      label: "Platinum",
      href: "/search/platinum",

      items: [
        {
          label: "Platinum Coins",
          href: "/search/platinum-coins",
        },
        {
          label: "Platinum Bars",
          href: "/search/platinum-bars",
        },
        {
          label: "IRA Approved Platinum",
          href: "/search/ira-approved-platinum",
        },
      ],
    },

    // {
    //   label: "Other",
    //   href: "/search/other",

    //   items: [
    //     {
    //       label: "Palladium",
    //       href: "/search/palladium",
    //     },
    //     {
    //       label: "Copper",
    //       href: "/search/copper",
    //     },
    //     {
    //       label: "Rhodium",
    //       href: "/search/rhodium",
    //     },
    //     {
    //       label: "Jewelry",
    //       href: "/search/jewelry",
    //     },
    //   ],
    // },

    // {
    //   label: "❄ Holiday ❄",
    //   href: "/search/holiday-collection",
    // },
    {
      label: "Top Sellers",
      href: "/search/collections/top-sellers",
    },
    {
      label: "Popular Products",
      href: "/search/collections/popular-products",
    },
    {
      label: "New Releases",
      href: "/search/collections/new-releases",
    },
    {
      label: "All Collections",
      href: "/collections",
    },
    {
      label: "All Categories",
      href: "/categories",
    },

    {
      label: "Sell To Us",
      href: "/sell",
    },

    {
      label: "View Our Auctions",
      href: "https://goldstandardauctions.hibid.com/",
      target: "_blank",
    },
  ];
}
