"use client";

import { useEffect, useState } from "react";
import Link from "next/link";

import { Separator } from "@gsa/ui/components/ui/separator";
import { Dialog, DialogContent, DialogTrigger } from "@gsa/ui/dialog";
import { MenuIcon } from "@gsa/ui/icons";

import { getMenu } from "~/lib/constants";
import { Logo } from "../../logo";

export default function MobileMenu() {
  const menu = getMenu("header-menu");
  const [isOpen, setIsOpen] = useState(false);
  const openMobileMenu = () => setIsOpen(true);
  const closeMobileMenu = () => setIsOpen(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <button
          type="button"
          onClick={openMobileMenu}
          aria-label="Open mobile menu"
          className="flex h-11 w-11 items-center justify-center rounded-md border border-neutral-200 text-black transition-colors dark:border-neutral-700 dark:text-white md:hidden"
        >
          <MenuIcon className="h-4" />
        </button>
      </DialogTrigger>

      <DialogContent className="fixed top-0 z-50 translate-y-2">
        <div className="p-4">
          <Logo />
          <div className="mb-4 w-full">{/* <Search /> */}</div>
          <ul className="flex w-full flex-col">
            {menu.map((item) => (
              <li
                className="py-2 text-xl text-black transition-colors hover:text-neutral-500 dark:text-white"
                key={item.label}
              >
                <Link
                  href={item.href ?? ""}
                  target={item.target}
                  onClick={closeMobileMenu}
                >
                  {item.label}
                </Link>
              </li>
            ))}

            <Separator className="my-4" />

            <li className="py-2 text-xl text-black transition-colors hover:text-neutral-500 dark:text-white">
              <Link href="/account/profile" onClick={closeMobileMenu}>
                My Account
              </Link>
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
}
