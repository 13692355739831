"use client";

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePostHog } from "posthog-js/react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";

import type { CreateNewsletterFormSubmissionSchema } from "@gsa/core/schemas/formSubmission";
import { createNewsletterFormSubmissionSchema } from "@gsa/core/schemas/formSubmission";
import { FormType } from "@gsa/db/schema";
import { Badge } from "@gsa/ui/components/ui/badge";
import { LoadingButton } from "@gsa/ui/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@gsa/ui/components/ui/form";
import { toast } from "@gsa/ui/components/ui/sonner";
import { CheckIcon } from "@gsa/ui/icons";
import { Input } from "@gsa/ui/input";

import { createNewsletterFormSubmission } from "~/lib/actions";

export default function NewsletterForm({
  onSubscribe,
}: {
  onSubscribe?: () => void;
}) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [sent, setSent] = useState(false);
  const posthog = usePostHog();
  const form = useForm<CreateNewsletterFormSubmissionSchema>({
    resolver: zodResolver(createNewsletterFormSubmissionSchema),

    defaultValues: {
      type: FormType.Newsletter,
    },
  });

  const onSubmit = async (input: CreateNewsletterFormSubmissionSchema) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not available yet");
      toast.error(
        "Execute recaptcha not available yet likely meaning key not recaptcha key not set",
      );
      return;
    }

    const result = await createNewsletterFormSubmission({
      ...input,
      recaptchaToken: await executeRecaptcha("newsletter_form_submit"),
    });

    if (result?.success) {
      setSent(true);
      //   toast(result?.message);
      //   router.push(`/dashboard/store/categories/${result.data?.id}`);
      setTimeout(() => setSent(false), 5000);
      onSubscribe?.();
      posthog.identify(
        input.data.email, // Replace 'distinct_id' with your user's unique identifier
        input, // optional: set additional user properties
      );

      form.reset();
      localStorage.setItem("newsletter-subscribed", "true");
    } else {
      toast(result?.message);
      form.reset();
    }
  };

  if (sent) {
    return (
      <div className="mt-6 gap-2 sm:flex sm:max-w-md lg:mt-0">
        <Badge variant="success" size="2xl">
          <CheckIcon className="h-5 w-5" /> Thanks for subscribing!
        </Badge>
      </div>
    );
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-6 gap-2 sm:flex sm:max-w-md lg:mt-0"
      >
        <FormField
          control={form.control}
          name="data.email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  value={field.value ?? ""}
                  type="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                  required
                  className="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:w-56 sm:text-sm sm:leading-6"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <LoadingButton
          type="submit"
          loading={form.formState.isSubmitting}
          disabled={form.formState.isSubmitting}
          className="flex w-full items-center justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
        >
          Subscribe
        </LoadingButton>
      </form>
    </Form>
  );
}
