"use client";

import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";

import type { CartWithFullDataSchema } from "@gsa/db/schema";
import { Badge } from "@gsa/ui/components/ui/badge";
import { Button } from "@gsa/ui/components/ui/button";
import { ArrowRightIcon, ShoppingCartIcon } from "@gsa/ui/icons";
import { createUrl } from "@gsa/ui/lib/utils";
import Price from "@gsa/ui/price";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@gsa/ui/sheet";

import { loader } from "~/lib/loader";
import { ProductImage } from "../product/image";
import { ProductPublicNote } from "../product/public-note";
// import { DeleteItemButton } from "./delete-item-button";
import { EditItemQuantityButton } from "./edit-item-quantity-button";
import OpenCart from "./open-cart";

export default function CartModal({
  cart,
}: {
  cart: CartWithFullDataSchema | undefined | null;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const quantityRef = useRef(cart?.totalQuantity);
  const openCart = () => setIsOpen(true);
  const closeCart = () => setIsOpen(false);
  const pathname = usePathname();

  useEffect(() => {
    // Open cart modal when quantity changes.
    if (cart?.totalQuantity !== quantityRef.current) {
      // But only if it's not already open (quantity also changes when editing items in cart).
      if (
        !isOpen &&
        !pathname.startsWith("/cart") &&
        (cart?.totalQuantity ?? 0) > 0
      ) {
        setIsOpen(true);
      }

      // Always update the quantity reference
      quantityRef.current = cart?.totalQuantity;
    }
  }, [isOpen, cart?.totalQuantity, quantityRef]);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <button
          type="button"
          aria-label="Open Cart"
          title="Open Cart"
          onClick={openCart}
        >
          <OpenCart quantity={cart?.totalQuantity} />
        </button>
      </SheetTrigger>
      <SheetContent className="flex flex-col items-center justify-between p-0 sm:max-w-[540px]">
        <SheetHeader className="w-full border-b p-5">
          <SheetTitle>Shopping Cart</SheetTitle>
          <SheetDescription>{/* <CloseCart /> */}</SheetDescription>
        </SheetHeader>

        {!cart || cart.items.length === 0 ? (
          <div className=" flex w-full flex-col items-center justify-center gap-4 overflow-hidden p-5">
            <ShoppingCartIcon className="h-16" />
            <p className="mt-6 text-center text-2xl font-bold">
              Your cart is empty.
            </p>
            <Button variant="success" onClick={closeCart}>
              Continue Shopping
            </Button>
          </div>
        ) : (
          <div className="flex w-full flex-col justify-between overflow-hidden  overflow-y-scroll">
            <ul className="flex-grow">
              {cart.items.map(
                (item: CartWithFullDataSchema["items"][0], i: number) => {
                  const merchandiseSearchParams = {};

                  const merchandiseUrl = createUrl(
                    `/products/${item.product?.slug}`,
                    new URLSearchParams(merchandiseSearchParams)
                  );

                  const featuredImage = item?.product?.images?.[0];

                  return (
                    <li
                      key={item.id}
                      className="relative flex w-full flex-col   border-b   p-3 dark:border-neutral-700"
                    >
                      <div className="text-md flex flex-1 flex-col  font-bold">
                        {item.product?.title}
                      </div>

                      <div className="relative flex w-full flex-row justify-between px-1 py-4">
                        <Link
                          href={merchandiseUrl}
                          onClick={closeCart}
                          className="z-30 flex flex-row space-x-4"
                        >
                          {!featuredImage && (
                            <div className="h-full w-full bg-gray-50" />
                          )}

                          {featuredImage && (
                            <ProductImage
                              className="aspect-square h-24 w-24 object-cover "
                              width={featuredImage.width ?? 512}
                              height={featuredImage.height ?? 512}
                              alt={item.product?.title}
                              loader={loader}
                              src={featuredImage?.path}
                            />
                          )}
                        </Link>

                        <div className="items-between mt-auto flex h-full  flex-col justify-between gap-3 ">
                          <Badge variant="secondary" className="flex-shrink">
                            <Price
                              className="text-lg text-muted-foreground"
                              amount={item.product.price}
                            />
                          </Badge>
                          <div className="ml-auto flex h-9 flex-row items-center rounded-full border border-neutral-200  ">
                            <EditItemQuantityButton
                              itemId={item.id}
                              type="minus"
                            />
                            <p className="w-6 text-center">
                              <span className="w-full text-sm">
                                {item.quantity}
                              </span>
                            </p>
                            <EditItemQuantityButton
                              itemId={item.id}
                              type="plus"
                            />
                          </div>
                          {/* <div className="ml-auto mt-auto">
                            <DeleteItemButton item={item} />
                          </div> */}
                        </div>
                      </div>

                      <ProductPublicNote publicNote={item.product.publicNote} />
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        )}

        <SheetFooter className="mt-auto w-full">
          <div className="flex w-full flex-col gap-4 border-t p-5">
            {cart && cart.items.length > 0 && (
              <>
                <div className="py-4 text-sm text-neutral-500 dark:text-neutral-400">
                  {/* <div className="mb-3 flex items-center justify-between border-neutral-200 pb-1 dark:border-neutral-700">
                    <p className="text-lg font-semibold text-black">Taxes</p>
                    <Price
                      className="text-right text-lg font-semibold text-black"
                      amount={cart.cost.totalTaxAmount}
                    />
                  </div> */}
                  <div className="mb-3 flex items-center justify-between  border-neutral-200 pb-1 pt-1 dark:border-neutral-700">
                    <p className="text-lg font-normal text-black/60">
                      Shipping
                    </p>
                    <Price
                      className="text-right text-lg font-semibold text-black"
                      amount={800}
                    />
                  </div>
                  <div className="mb-3 flex items-center justify-between  border-neutral-200 pb-1 pt-1 dark:border-neutral-700">
                    <p className="text-lg font-normal text-black/60">Total</p>
                    <Price
                      className="text-right text-lg font-semibold text-black"
                      amount={cart.cost.totalAmount}
                    />
                  </div>
                </div>

                <Link href={"/cart/checkout"}>
                  <Button
                    variant="success"
                    onClick={closeCart}
                    size="lg"
                    className="w-full"
                  >
                    Proceed to Checkout
                  </Button>
                </Link>

                <div className="text-center">
                  <Button
                    variant="link"
                    size="lg"
                    onClick={closeCart}
                    className="text-gray-400 "
                  >
                    or{" "}
                    <span className="flex items-center gap-1 text-green-600 underline">
                      Continue Shopping <ArrowRightIcon className="h-3 w-3" />
                    </span>
                  </Button>
                </div>
              </>
            )}
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
