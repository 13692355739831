import Image from "next/image";

import { loader } from "~/lib/loader";

export function SpacerImage() {
  return (
    <Image
      priority
      loader={loader}
      src={"/gsa-production/images/spacer/raw"}
      width={1920}
      height={80}
      alt="Spacer"
      draggable={false}
      className="h-auto w-full select-none object-contain print:hidden"
    />
  );
}
