import type { VariantProps } from "class-variance-authority";
import type * as React from "react";
import { cva } from "class-variance-authority";

import { cn } from "../../lib/utils";

const badgeVariants = cva(
  "inline-flex items-center gap-1 rounded-full border    font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive: "bg-destructive-100 border-destructive text-red-600 ",

        success: "border border-green-300 bg-green-50 text-green-900  ",
        warning:
          "hover:bg-warning/80 border border-yellow-600 bg-yellow-100 text-yellow-600",
        blue: "hover:bg-blue/80 border border-blue-600 bg-blue-100 text-blue-600",
        sky: "hover:bg-sky/80 border border-sky-400 bg-sky-100 text-sky-400",
        light:
          "border-transparent bg-slate-100 text-slate-900 hover:bg-slate-200",
        dark: "border-transparent bg-slate-700 text-slate-100 hover:bg-slate-800",
        black: "border-transparent bg-black text-slate-100 hover:bg-slate-800",
        white: "border-transparent bg-white text-slate-900 hover:bg-slate-200",
        outline: "bg-white text-foreground",
        disabled: "bg-white text-foreground/20",
      },
      size: {
        xs: "px-1.5 py-0.5 text-xs",
        sm: "px-2 py-0.5 text-xs",
        md: "px-3 py-0.5 text-sm",
        lg: "px-4 py-1 text-sm",
        xl: "px-5 py-1.5 text-base",
        "2xl": "px-6 py-2 text-lg",
        "3xl": "px-8 py-4 text-xl",
        "4xl": "px-10 py-6 text-2xl",
        "5xl": "px-12 py-8 text-3xl",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size = "md", ...props }: BadgeProps) {
  return (
    <div
      className={cn(
        "word-nowrap text-nowrap",
        badgeVariants({ variant, size }),
        className,
      )}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
