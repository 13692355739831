"use client";

import { useEffect, useRef } from "react";
import { usePathname, useRouter } from "next/navigation";
import { useQueryState } from "nuqs";
import { useFormStatus } from "react-dom";

import { SearchIcon } from "@gsa/ui/icons";
import { Input } from "@gsa/ui/input";
import { useBackpressure } from "@gsa/ui/lib/use-backpressure";

function SearchBase() {
  const [query, setQuery] = useQueryState("q");
  const inputRef = useRef<HTMLInputElement>(null);
  const { triggerUpdate, shouldSuspend, formRef } = useBackpressure();
  const router = useRouter();
  const pathname = usePathname();

  async function handleSubmit(formData: FormData) {
    const newQuery = formData.get("q") as string;
    await setQuery(newQuery);
    if (pathname.startsWith("/search")) {
      router.push(`${pathname}?q=${encodeURIComponent(newQuery)}`);
    } else {
      router.push(`/search?q=${encodeURIComponent(newQuery)}`);
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    setQuery(newValue);
    formRef.current?.requestSubmit();
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length,
      );
    }
  }, []);

  if (shouldSuspend) {
    return null; // or a loading indicator
  }

  return (
    <form
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(new FormData(e.currentTarget));
      }}
      className="relative flex w-full flex-1 flex-shrink-0 rounded shadow-sm"
    >
      <label htmlFor="q" className="sr-only">
        Search
      </label>
      <SearchIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
      <Input
        ref={inputRef}
        onChange={handleInputChange}
        type="text"
        name="q"
        id="q"
        placeholder="Search for coins..."
        value={query ?? ""}
        className="px-8"
      />
      <LoadingSpinner />
    </form>
  );
}

function LoadingSpinner() {
  const { pending } = useFormStatus();

  return (
    <div
      data-pending={pending ? "" : undefined}
      className="absolute right-3 top-1/2 -translate-y-1/2 transition-opacity duration-300"
    >
      <svg className="h-5 w-5" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="currentColor"
          strokeWidth="10"
          strokeDasharray="282.7"
          strokeDashoffset="282.7"
          className={pending ? "animate-fill-clock" : ""}
          transform="rotate(-90 50 50)"
        />
      </svg>
    </div>
  );
}

export function Search() {
  return <SearchBase />;
}
