import { AlertTriangleIcon } from "@gsa/ui/icons";

export function ProductPublicNote({
  publicNote,
}: {
  publicNote: string | null | undefined;
}) {
  if (!publicNote) {
    return null;
  }
  return (
    <div className="mt-4 flex items-center gap-2 rounded border border-blue-400 bg-blue-50 p-3 text-sm text-blue-400">
      <AlertTriangleIcon className="h-4 w-4 " />
      <span className="font-bold">NOTE:</span> {publicNote}
    </div>
  );
}
