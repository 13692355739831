"use client";

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePostHog } from "posthog-js/react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";

import type { CreateSpeakToSalesRepFormSubmissionSchema } from "@gsa/core/schemas/formSubmission";
import { createSpeakToSalesRepFormSubmissionSchema } from "@gsa/core/schemas/formSubmission";
import { FormType } from "@gsa/db/schema";
import { LoadingButton } from "@gsa/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@gsa/ui/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@gsa/ui/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@gsa/ui/components/ui/form";
import { toast } from "@gsa/ui/components/ui/sonner";
import { Input } from "@gsa/ui/input";
import { Textarea } from "@gsa/ui/textarea";

import { createSpeakToASalesRepFormSubmission } from "~/lib/actions";

export default function SpeakToSalesRepForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const posthog = usePostHog();
  const [sent, setSent] = useState(false);
  const [open, setOpen] = useState(false);

  const form = useForm<CreateSpeakToSalesRepFormSubmissionSchema>({
    resolver: zodResolver(createSpeakToSalesRepFormSubmissionSchema),

    defaultValues: {
      type: FormType.SpeakToSalesRep,
    },
  });

  const onSubmit = async (input: CreateSpeakToSalesRepFormSubmissionSchema) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not available yet");
      toast.error(
        "Execute recaptcha not available yet likely meaning key not recaptcha key not set",
      );
      return;
    }

    const result = await createSpeakToASalesRepFormSubmission({
      ...input,
      recaptchaToken: await executeRecaptcha("speak_to_sales_rep_form_submit"),
    });

    if (result?.success) {
      setSent(true);
      setOpen(true);
      //   toast(result?.message);
      //   router.push(`/dashboard/store/categories/${result.data?.id}`);
      posthog.identify(
        input.data.email ?? input.data.name, // Replace 'distinct_id' with your user's unique identifier
        input, // optional: set additional user properties
      );
      setTimeout(() => {
        setSent(false);
        setOpen(false);
      }, 5000);
    } else {
      toast(result?.message);
    }
  };

  if (sent) {
    return (
      <Dialog open={open} onOpenChange={() => setOpen(false)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Thank you</DialogTitle>
            <DialogDescription>
              Your message has been received. We'll get back to you shortly.
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Speak To A Sales Rep</CardTitle>
        <CardDescription>Get in touch with us</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="relative grid gap-8 "
          >
            <FormField
              control={form.control}
              name="data.name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Name <span className="text-red-600">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={field.value ?? ""}
                      type="text"
                      autoComplete="name"
                      placeholder="Enter your name"
                      required
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="data.email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={field.value ?? ""}
                      type="email"
                      autoComplete="email"
                      placeholder="Enter your email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="data.phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={field.value ?? ""}
                      type="tel"
                      autoComplete="tel"
                      placeholder="Enter your phone number"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="data.message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Message <span className="text-red-600">*</span>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      value={field.value ?? ""}
                      placeholder="Enter your message"
                      required
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <LoadingButton
              loading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting}
            >
              Submit
            </LoadingButton>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
