"use client";

import { useState } from "react";
import Image from "next/image";

import { CoinsIcon } from "@gsa/ui/icons";
import { cn } from "@gsa/ui/lib/utils";

import { createCDNUrl } from "~/lib/cdn";

interface Props {
  src: string;
  width: number;
  height: number;
  alt: string;
  loader: ({
    src,
    width,
    quality,
  }: {
    src: string;
    width: number;
    quality?: number;
  }) => string;
  priority?: boolean;
  className?: string;
}
export function ProductImage({
  src,
  width,
  height,
  alt,
  loader,
  priority,
  className,
}: Props) {
  const [error, setError] = useState<string>();

  if (!!error || !src || !width || !height) {
    return (
      <div
        className={cn(
          "flex h-24  w-24  items-center justify-center rounded-xl bg-slate-50",
          className,
        )}
      >
        <CoinsIcon className="h-12 w-12 text-slate-100" />
      </div>
    );
  }

  return (
    <Image
      loader={loader}
      src={error ? "" : createCDNUrl(src)}
      width={width ?? 512}
      height={height ?? 512}
      alt={alt}
      draggable={false}
      className={cn("aspect-square object-contain", className)}
      priority={priority}
      onError={() => {
        setError("Failed to load image");
      }}
    />
  );
}
